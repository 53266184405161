<template>
  <div class="page-content">
    <page-breadcrumb title="Gift Categories" class="mb-2" />

    <data-create-popup title="Create Category"
      :schema="create_schema" :default-data="{name: '', price: 0, thumbnail: null, language: null}"
      :create-data-fn="createItem" @create="$refs.category_list.getList()"
    />
    <data-table-ssr id="category_list" ref="category_list" :limit-base="9" pagination
      :columns="fields" :get-list-fn="getList"
      :to-edit-fn="toEditPage"
    />
  </div>
</template>

<script>
import service from '../service_category'

const create_schema = [
  {
    cols: 4,
    fields: [{ label: 'Thumbnail', field: 'thumbnail', input_type: 'image', validate: { required: true } }],
  },
  {
    cols: 8,
    fields: [
      { label: 'Name', field: 'name', input_type: 'text', validate: { required: true } },
      { label: 'Price', field: 'price', input_type: 'number', validate: { type: 'number', min: 0 } },
    ]
  }
];

const fields = [
  { label: 'ID', field: 'id' },
  { label: 'Thumb', field: 'thumbnail', input_type: 'image' },
  { label: 'Name', field: 'name' },
  { label: 'Price', field: 'price' },
  { label: 'Created At', field: 'insert_datetime', input_type: 'datetime' },
]

export default {
  data(){
    return{
      create_schema,
      fields,
      total: 0,
    }
  },
  computed: {
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create({ ...data, language: this.authLanguage });
      return new_item;
    },
    async getList({ limit, page, query, searchTerm }) {
      let response = await service.getList({
        query: JSON.stringify({ ...query, language: this.authLanguage }),
        page, limit
      });
      let list = [], total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
        this.total = total;
      }
      return { list, total };
    },
    toEditPage({id}) {
      this.$router.push({ name: 'earn-gift-category', params: { category_id: id } });
    },
  }
}
</script>
